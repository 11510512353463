

:root {
  --box-border-color: rgba(255, 255, 255, 0.38);
  min-width: 100%;
  
}


body {
  background: rgb(0, 0, 0);
  justify-content: center;
  display: flex;
}


span {
  display: inline-block;
  text-align: center;
  font-weight: 500;
}


div {
  text-align: center;
}

link {
  color: white;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #282828;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.container {
  justify-content: center;
  display: grid;
  padding: 10px 10px 20px 10px;
  border-radius: calc(0.5 * 100px);
  box-shadow: 0 1px 6px 0 var(--box-border-color);
  max-width: fit-content;
}


.subtitle, .title, 
.cube, .cone, 
.teleopCheckbox, .autoCheckbox, .dissapointmentCheckbox {
  color: rgb(219, 186, 0);
  padding-top: 5px;
  font-size: 27px;
}

.title {
  stroke:white;
}

.scouting-guidelines {
  color: gold;
}

.suggestions {
  color: gold;
  font-size: 20px;
}

.dropdown-auto {
  color: black;
  border-radius: calc(0.5 * 100px);
  box-shadow: 0 1px 6px 0 var(--box-border-color);
  border-width: 2px;
  padding-left: 30px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 20px;
  background-color: white;
  margin-top: 5px;
}

.dropdown {
  color: black;
  border-radius: calc(0.5 * 100px);
  box-shadow: 0 1px 6px 0 var(--box-border-color);
  padding-left: 40px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 20px;
  background-color: white;
  margin-top: 5px;
}

.section-title {
  border-radius: calc(0.5 * 100px);
  box-shadow: 0 1px 6px 0 var(--box-border-color);
  padding-bottom: 10px;
  color: rgba(0, 255, 0, 0.555);
}


.text-box {
  border-radius: calc(0.5 * 100px);
  box-shadow: 0 1px 6px 0 var(--box-border-color);
  height: 30px;
  width: 100px;
  font-size: 25px;
}


.text-box-long {
  border-radius: calc(15px);
  box-shadow: 0 1px 6px 0 var(--box-border-color);
  height: 75px;
  width: 350px;
  font-size: 25px;
}


.widget {
  padding: 10px;
}

.widgetteleop-counter {
  width: 100%;
}


.identification-container, .auto-container, .teleop-container, .post-match-container {
  padding: 10px 10px 20px 10px;
  border-radius: calc(0.5 * 100px);
  box-shadow: 0 1px 6px 0 var(--box-border-color);
  
}


.btn-container {
  display: flex;
  justify-content: center;
}


.btn {
  height: 70px;
  width: 55px;
  font-size: 50px;
  text-align: center;
  display: inline-block;
  color: black;
  background-color: gray;
  border-radius: 15px;
  border-radius: 30px;
  border-color: black;
  padding-top: 0%;
  padding: 0%;
  padding-left: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
}

.value {
  padding: 10px;
  color: rgb(255, 255, 255);
}


.cone {
  height: 75px;
  width: auto;
  padding-right: 30px;
}


.cube {
  height: 75px;
  width: auto;
  padding-left: 50px;
}


.checkbox {
  height: 35px;
  width: 35px;
  background-color: #ccc;
  border-radius: calc(100px);
  float: center;
}
.style1 {
  height: 1000;
}


.export, .clear-localstorage {
  background-color: #ccc;
  border-radius: calc(100px);
  box-shadow: 0 1px 6px 0 var(--box-border-color);
  margin: 2%;
  padding-top: 2%;
}

.submit {
  padding: 10px;
  background-color: #ccc;
  border-radius: calc(100px);
  box-shadow: 0 1px 6px 0 var(--box-border-color);
  font-size: 40px;
  margin: 2%;
  padding-top: 2%;
  width: 200px;
}

.double-line {
  max-width: 250px;
}
.slider {
  width: 200px;
  height: 25px;
  background: rgb(47, 49, 45);
  padding: 0;
  position: center;
}

 .slider-valueslide {
  position: center;
  padding: 0;
  padding-left: 0;
  color: rgb(207, 203, 203);
}

.slider-nums {
  padding-bottom:0;
  color: white;
}

.slider-reference {
  padding: 0;
  font-size: small;
  color: rgb(184, 184, 184);
}

.cubes {
  color: rgb(128, 108, 218);
}

.fumbles {
  color: rgb(232, 0, 0)
}
.label {
  font-size: 15px;
  color: white;
  width: fit-content;
  padding-left: 2%;
  
}
.temporary, .critical, .defend, .subtitleonstage {
  color: rgb(255, 215, 0);
}

.dbtn {
  padding-bottom: 20px;
}
.checkboxes {
  padding-top: 20px;
}

.cookie {
  padding-top: 10px;
  
}
.text-box-long {
  font-size: 16px;
  min-width: 300px;
  min-height: 70px;
  max-width: 380px;
  max-height: 200px;
}

.text-box-long {
  text-indent: 4%;

}

.checkboxes1 {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: flex-end;
  width: 85%;
  margin: 0 auto;
}
.checkboxes2 {
  display: grid;
  grid-template-rows: auto auto auto;
  align-items: center;
  justify-items: center;
  width: 75%;
  margin: 0 auto;
}

.stop-watch {
  color: white;
  padding: 10px 10px 20px 10px;
  border-radius: calc(0.5 * 100px);
  box-shadow: 0 1px 6px 0 var(--box-border-color);
  margin-top: 5px;
  position: relative;
}

.stopwatch-btn {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  border: none;
  background-color: gray;
  display: inline-flex;
  align-items: center;
} 

.stopwatch-btn:active {
  background-color: rgba(255, 255, 255, 0.38);
}

.timer {
  font-size: 30px;
}

.average-time {
  font-size: 15px;
}

.stopwatch-btn-text {
  font-weight: bold;
  font-size: 20px;
  color: black;
  width: 70px;
  padding: 0;
  margin: 0;
}

.btn-text {
  color: black;
}

.val-display {
  color: white;
  font-size: 19px;
  height: 60px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
}

.awsome-val-display {
  display: grid;
  grid-template-columns: 75% 20%;
}

.auto-pieces-body {
  color: white;
  border-radius: calc(0.5 * 100px);
  box-shadow: 0 1px 6px 0 var(--box-border-color);
  padding-bottom: 10px;
  width: 380px;
  align-content: space-evenly;
}

.auto-dropdowns {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auto-dropdown {
  width: 100px;
  height: 50px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 20px;
  text-align: center;
  border: none;
  border-radius: 25px;
  color: yellow;
}

.auto-btn-text {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: black;
  width: 100px;
  padding: 0;
  margin: 0;
}

.indicator {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 12px;
  width: 50px;
  text-align: center;
}

.slider-label {
  margin-bottom: 0px;
}

.field-map {
  user-select: none;
  background-color: rgb(54, 54, 54);
  width: 400px;
  height: 300px;
  border-radius: 20px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: auto auto auto;
  text-align: center;
  align-content: center;
}

.active-blue-button {
  background-color: blue;
  height: 60px;
  width: 90px;
  border-style: inset;
  border-radius: 20px;
  border-color: darkblue;
  border-width: 5px;
  font-size: 22px;
  margin-right: 10px;
}

.inactive-blue-button {
  background-color: navy;
  height: 60px;
  width: 90px;
  border-style: outset;
  border-radius: 20px;
  border-color: darkblue;
  border-width: 5px;
  font-size: 22px;
  margin-right: 10px;
}

.active-red-button {
  background: red;
  height: 60px;
  width: 90px;
  border-style: inset;
  border-radius: 20px;
  border-color: darkred;
  border-width: 5px;
  font-size: 22px;
  margin-left: 10px;
}

.inactive-red-button {
  background: maroon;
  height: 60px;
  width: 90px;
  border-style: outset;
  border-radius: 20px;
  border-color: darkred;
  border-width: 5px;
  font-size: 22px;
  margin-left: 10px;
}

.alliance-text {
  color: white;
  font-weight: 750;
}

.scoring {
  height: 300px;
  width: 200px;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  justify-content: center;
}

.reef-section {
  margin-top: 5px;
  display: grid;
  grid-template-rows: 45px 45px 45px auto;
  width: 150px;
  height: 300px;
  justify-items: center;
  justify-content: center;
}

.reef-base-graphic {
  margin: 0 auto;
  margin-top: 20px;
  height: 170px;
  width: auto;
  position: absolute;
  align-self: flex-start;
  z-index: 0.5;
}

.staged-tree {
  display: flex;
  border-radius: 10px;
  height: 100px;
  width: 100px;
  justify-self: center;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.staged-tree:active {
  background: rgb(40, 40, 40);
}

.staged-tree-on {
  display: flex;
  background: rgb(120, 120, 120);
  border-radius: 10px;
  height: 100px;
  width: 100px;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.staged-tree-on:active {
  background: rgb(40, 40, 40);

}


.processor-location {
  color: white;
  font-size: 20px;
  font-style: oblique;
}

.processor-location-disabled {
  font-size: 20px;
  font-style: oblique;
  color: transparent;
}

.reef-top-position {
  margin: 0 auto;
  margin-top: 40px;
  display: grid;
  grid-template-columns: auto auto;
  z-index: 2;
}

.reef-middle-position {
  margin: 0 auto;
  margin-top: 40px;
  display: grid;
  grid-template-columns: 100px 100px;
  z-index: 2;
}

.reef-bottom-position {
  margin: 0 auto;
  margin-top: 40px;
  display: grid;
  grid-template-columns: auto auto;
  z-index: 2;
}

.wing {
  display: grid;
  grid-template-rows: 33% 33% 33%;
}

.center {
  display: grid;
  grid-template-rows: 20% 20% 20% 20% 20%;
}

.undo-button-text:active {
  background-color: rgb(40, 40, 40);
}

.fail {
  display: flex;
  grid-template-rows: 5% 5%;
  border-radius: 10px 10px 10px 10px;
  padding: 5px;
}

.fail-button {
  background-color: goldenrod;
  border-style: outset;
  border-color: darkgoldenrod;
  border-width: 3px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-items: center;
  height: 70px;
  width: 90px;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 700;
  transition: 0.12s;
  margin-bottom: 30px;
}

.fail-button:active {
  background: darkgoldenrod;
  border-style: inset;
}

.algae-button {
  background: olivedrab;
  border-style: outset;
  border-color: darkgreen;
  border-width: 3px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-items: center;
  height: 75px;
  width: 90px;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 700;
  margin-top: 30px;
}

.algae-button:active {
  background: darkolivegreen;
  border-style: inset;
}

.algae-text {
  margin: 0 auto;
  color: white;
}

.reef-button {
  margin: 0 auto;
  display: flex;
  height: auto;
  width: auto;
  border-style: none;
  align-items: center;
  justify-items: left;
  border-radius: 5px;
  background: none;
}

.reef-button:active {
  background-color: darkgray;
}

.place-button {
  background-color: navy;
  border-style: outset;
  border-color: darkblue;
  border-width: 3px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 70px;
  border-radius: 15px;
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.place-button:active {
  background: blue;
  border-style: inset;
}

.place-button-red {
  background-color: maroon;
  border-style: outset;
  border-color: darkred;
  border-width: 3px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 70px;
  border-radius: 15px;
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.place-button-red:active {
  background: red;
  border-style: inset;
}

.reef-button-display {
  background-color: navy;
  border-style: outset;
  border-color: darkblue;
  border-width: 3px;
  margin: 0 auto;
  display: flex;
  height: 38px;
  width: 38px;
  font-size: 25px;
  border-radius: 50%;
  z-index: 1;
}

.reef-button-display:active {
  background-color: blue;
  border-style: inset;
}

.reef-button-display-red {
  background-color: maroon;
  border-style: outset;
  border-width: 3px;
  border-color: darkred;
  margin: 0 auto;
  display: flex;
  height: 38px;
  width: 38px;
  font-size: 25px;
  border-radius: 50%;
  z-index: 1;
}

.reef-button-display-red:active {
  background: red;
  border-style: inset;
}

.reef-button-display-on {
  background-color: darkorchid;
  border-style: outset;
  border-color: darkmagenta;
  border-width: 3px;
  margin: 0 auto;
  display: flex;
  height: 38px;
  width: 38px;
  font-size: 25px;
  border-radius: 50%;
  z-index: 1;
}

.reef-button-display-on:active {
  background: magenta;
  border-style: inset;
}

.algae-box {
  height: 300px;
  width: auto;
  display: grid;
  grid-template-rows: auto auto auto auto;
  align-items: center;
  justify-items: center;
}

.processor-button {
  display: flex;
  background-color: navy;
  border-style: outset;
  border-color: darkblue;
  border-width: 3px;
  height: 40px;
  width: 120px;
  border-radius: 15px;
  font-size: 20px;
  align-items: center;
  margin: auto 0px;
}

.processor-button:active {
  background-color: blue;
  border-style: inset;
}

.processor-button-red {
  display: flex;
  background-color: maroon;
  border-style: outset;
  border-width: 3px;
  border-color: darkred;
  height: 40px;
  width: 120px;
  border-radius: 15px;
  font-size: 20px;
  align-items: center;
  margin: auto 0px;
}

.processor-button-red:active {
  background-color: red;
  border-style: inset;
}

.processor-text {
  margin: 0 auto;
  color: white;
  font-weight: 750;
}

.fumble-algae-button {
  background: goldenrod;
  border-style: outset;
  border-color: darkgoldenrod;
  border-width: 3px;
  height: 60px;
  width: 120px;
  border-radius: 15px;
  align-content: center;
  justify-items: center;
  font-size: 20px;
}

.fumble-algae-button:active {
  background: darkgoldenrod;
  border-style: inset;
}

.fumble-algae-text {
  color: white;
  font-weight: 750;
}

.autolevel-text {
  margin: 30%;
  color: white;
  font-weight: 750;
}

.undo-button-text {
  display: flex;
  align-items: center;
  width: 70px;
  height: 60px;
  border-radius: 40px;
  transition: 0.12s;
  font-style: bold;
  color: white;
}

.fail-text, 
.value-text, 
.undo-button-text {
  margin: 0 auto;
}

.value-text, 
.undo-button-text,
.reef-base-text,
.fail-text {
  color: white;
}

.misc {
  display: grid;
  grid-template-rows: auto auto auto;
}

.value-display {
  display: flex;
  justify-self: center;
  align-items: center;
  width: 60px;
  height: 40px;
  font-size: 30px;
  font-weight: 650;
  background: black;
  border-style: inset;
  border-color: lightgray;
  border-width: 5px;
}

.undo-button {
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.undo-img {
  height: 40px;
  color: white;
  margin: 0 auto;
}

.section-container-teleop {
  display: flex;
  align-items: center;
}

.field-section {
  margin: 0 auto;
  display: flex;
  align-items: center;
  border-radius: 20px;
  background-color: rgb(45, 45, 45);
  height: 90%;
  width: 90%;
  transition: 0.12s;
}

.field-section-text {
  color: white;
  margin: 0 auto;
}

.inner-flex {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.field-column {
  display: grid;
  grid-template-rows: 20% 80%;
}

.upper-section {
  display: flex;
  align-items: center;
}

.pass-button {
  margin: 0 auto;
  background-color: rgb(7, 242, 35);
  display: flex;
  align-items: center;
  width: 90%;
  height: 90%;
  border-radius: 20px;
  transition: 0.12s
}

.pass-button:active {
  background-color: green;
}

.field-section:active {
  background-color: rgb(32, 32, 32);
}

.pass-text {
  margin: 0 auto;
  color: black;
}

.intake-text,
.intake-value-text {
  margin: 0 auto;
  color: white;
}

.intake-value-text {
  font-size: 25px;
}

.score-cell {
  user-select: none;
  margin: 0 auto;
  min-height: 40px;
  width: 90%;
  margin-bottom: 5px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 75% 25%;
}

.log-display-teleop {
  color: white;
  font-size: .9rem;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.teleop-counter-container {
  display: grid;
  width: 100%;
}

.score-btn-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 40%;
    float: right;
}

.log-container {
  width: 66%;
  height: 150px;
  border-style: solid;
  border-color: white;
  border-radius: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-width: 2px;
  justify-self: center;
}

.score-cell-text {
  background-color: #363636;
  display: flex;
  align-items: center;
  border-radius: 20px 0px 0px 20px;
}

.score-cell-remove {
  background-color: red;
  color: black;
  display: flex;
  align-items: center;
  border-radius: 0px 20px 20px 0px;
  font-weight: bold;
}

.score-cell-remove:active {
  background-color: maroon;
}

.cell-text {
  margin: 0 auto;
}

.reef-map {
  display: grid;
  grid-template-columns: auto auto;
  height: 300px;
  width: 400px;
  background: rgb(54, 54, 54);
  border-radius: 10px;
  margin-top: 10px;
}

.teleopCounters {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.teleop-scoring {
  display: grid;
  grid-template-rows: auto auto;
  align-content: center;
  height: 300px;
  width: 200px;
  justify-content: center;
}

.teleop-misic {
  display: grid;
  grid-template-rows: auto auto auto;
  height: 300px;
  width: 190px;
}

.teleop-algae-box {
  display: grid;
  grid-template-rows: auto auto;
  gap: 2px;
  height: 120px;
  width: auto;
  justify-items: center;
  align-items: center;
}

.processor-btn {
  display: flex;
  background-color: rgb(6, 195, 132);
  border-style: solid;
  border-color: rgb(0, 108, 72);
  border-width: 5px;
  border-radius: 15px;
  height: 40px;
  width: 120px;
  font-size: 22px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.processor-btn:active {
  background: rgb(0, 108, 72);
}

.processor-btn-text {
  color: white;
  font-weight: 850;
}

.teleop-display {
  display: flex;
  margin: 0 auto;
  height: 50px;
  width: 100px;
  background: black;
  border-style:inset;
  border-color: lightgray;
  border-width: 5px;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.teleop-display-text {
  color: white;
  font-weight: 750;
}

.teleop-score-box {
  display: grid;
  grid-template-rows: auto auto;
  height: 120px;
  width: auto;
  align-items: center;
  justify-items: center;
}

.score-btn {
  display: flex;
  background-color: green;
  height: 35px;
  width: 100px;
  font-size: 20px;
  border-style: solid;
  border-color: darkgreen;
  border-width: 5px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.score-btn:active {
  background-color: darkgreen;
}

.score-btn-text {
  color: white;
  font-weight: 800;
}

.fumble-btn {
  display: flex;
  background-color: red;
  height: 35px;
  width: 100px;
  font-size: 20px;
  border-style: solid;
  border-color: maroon;
  border-width: 5px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.fumble-btn:active {
  background-color: maroon;
}

.fumble-btn-text {
  color: white;
  font-weight: 800;
}

.teleop-reef-scoring {
  display: grid;
  grid-template-columns: auto auto;
  height: auto;
  width: auto;
  margin: 0 auto;
}

.teleop-buttons {
  height: 230px;
  width: auto;
  display: grid;
  grid-template-rows: auto auto auto auto;
  justify-self: left;
  margin: 0 auto;
}

.tele-btn {
  display: flex;
  background: darkorchid;
  border-style: solid;
  border-color: indigo;
  border-radius: 15px;
  border-width: 5px;
  width: 60px;
  height: 40px;
  margin: 0 auto;
  font-size: 24px;
  align-items: center;
  justify-content: center;
}

.tele-btn:active {
  background: purple;
}

.tele-btn-text {
  color: white;
  font-weight: 800;
}

.remove-algae-teleop {
  display: flex;
  background: rgb(0, 140, 100);
  border-style: solid;
  border-color: rgb(0, 100, 80);
  border-width: 5px;
  height: 40px;
  width: 150px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 5px;
}

.remove-algae-teleop:active {
  background: rgb(0, 170, 140);
}

.remove-algae-text {
  margin: 0 auto;
  color: white;
  font-weight: 750;
  font-size: 18px;
}

.reef-graphic {
  align-self: center;
  height: 230px;
  width: auto;
}